import React from 'react';
import '../App.css';

export const Home = () => (
  <div className="App">
    <header className="App-header">
      <img src="https://i.imgur.com/m13052z.jpg" className="App-logo" alt="logo" />
    </header>
  </div>
)
