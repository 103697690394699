import React from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Home } from './components/home';
import { Profile } from './components/profile';

export const App = () => (
  <Router>
  <Route exact path="/" component={Home} />
  <Route exact path="/:id" component={Profile} />
</Router>
)

export default App;
