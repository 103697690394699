import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

export const Profile = () => {
  const { id } = useParams();
  const [profile, setProfile] = useState();

  const fetchProfile = async () => {
    try {
      const response = await axios.post(`/.netlify/functions/profile`, { id });
      setProfile(response.data);
      console.log(response.data);
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    fetchProfile();
  }, []); // eslint-disable-line

  const renderProfile = () => (
    <>
    <img src={profile.image} style={{maxWidth: '500px'}} alt={'profile'} />
    <p>{profile.name || 'unnamed character'}</p>
    <p>Moves:</p>
    <div>
    {
      profile.moves && profile.moves.map(move => (
        <>
          <img src={move.picture} style={{maxWidth: '500px'}} alt={'move'} />
          <p>{move.name}: {move.description}</p>
        </>
      ))
    }
    </div>
    </>
  )


  return (
    <div className="App">
      <header className="App-header">
      { profile ? renderProfile() : 'Loading...' }
      </header>
    </div>
  )

}
